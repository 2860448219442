import Model from './Model'


interface CalculateResponse {
  success: boolean,
  id: string,
  error: string | null,
}

interface StatusResponse {
  status: string,
  progress: number,
  error: string | null,
}

export const calculate = async (model: Model): Promise<CalculateResponse> => {
  const rv = await fetch('api/project/calculate', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(model),
  })
  const response = await rv.json()
  return response as CalculateResponse
}

export const getStatus = async (id: string): Promise<StatusResponse> => {
  const rv = await fetch(`api/project/status/${id}`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: '',
  })
  const response = await rv.json()
  return response as StatusResponse
}

// utils
export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
