import React from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.css'
import Intro from './Intro'
import Wizard from './Wizard'
import Outro from './Outro'
import {EquipmentProvider} from './EquipmentContext'


const App = () => {
  return (
    <>
      <header className='justify-content-center WB-header'>
        <div id='header' className='flex-row align-items-center WB-header-container'>

          <a href='https://www.esmap.org/' target='_blank'>
            <img src='logo/esmap.png' alt='ESMAP' id='esmap' /></a>
            <img src='logo/WBG-EnergyAndExtractives-Horizontal-RGB.jpg' alt='WBG' id='wbg' />
       </div>
      </header>

      <main className='container align-self-center flex-grow-1 flex-shrink-0 WB-div'>
        <Intro/>
        <hr/>
        <EquipmentProvider>
          <Wizard/>
        </EquipmentProvider>
        <hr/>
        <Outro/>
      </main>

      <footer>
        <div className='p-2'><a href="http://www.homerenergy.com/">HOMER Energy, LLC © {new Date().getFullYear()}</a></div>
      </footer>
    </>
  )
}

export default App
