/* global google */
import React, {KeyboardEvent, useEffect, useRef, useState} from 'react'
import {StepProps} from './Wizard'
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api'


interface Coords {
  lat: number,
  lng: number
}

interface Location {
  coords: Coords,
  address: string
}

type Props = StepProps<Location>


// address

const LocationAddress = ({value, onChange}: Props) => {
  const [query, setQuery] = useState('')
  const inputEl = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const element = inputEl.current!
    const autocomplete = new google.maps.places.Autocomplete(element, {types: ['address']})

    const handlePlaceChanged = () => {
      const place = autocomplete.getPlace()
      if (place.formatted_address && place.geometry) {
        const address = place.formatted_address
        const coords = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}
        setQuery(address)
        onChange({address, coords})
      }
    }

    autocomplete.addListener('place_changed', handlePlaceChanged)
  })

  const handleChange = (x: string) => {
    setQuery(x)
  }

  const handleSearch = () => {
    // clear query & search
    findLocation(query, (val: Location) => {
      const value = {address: val.address, coords: val.coords}
      onChange(value)
    })
    setQuery('')
  }

  const handleKeyUp = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') { handleSearch() }
  }

  return (
    <div className='input-group WB-location'>
      <input className='form-control WB-location-control'
             ref={inputEl}
             value={query}
             onChange={(ev) => handleChange(ev.target.value)}
             onKeyUp={handleKeyUp}/>
      <div className='input-group-append'>
        <button className='btn btn-outline-secondary WB-location-button' type='button' onClick={handleSearch}>Search</button>
      </div>
    </div>
  )
}


// coords

const geocode = ({lat, lng}: Coords, complete: (address: string) => void) => {
  // reverse geocoding
  let geocoder = new google.maps.Geocoder()
  geocoder.geocode({'location': {lat, lng}}, (results, status) => {
    if (status === google.maps.GeocoderStatus.OK && results[0]) {
      const address = results[0].formatted_address
      complete(address)
    }
    return ''
  })
}

const findLocation = (query: string, complete: (rv: Location) => void) => {
  // geocoding
  let geocoder = new google.maps.Geocoder()
  geocoder.geocode({address: query}, (results, status) => {
    if (status === google.maps.GeocoderStatus.OK && results[0]) {
      const coords = results[0].geometry.location
      const addr = results[0].formatted_address
      complete({address: addr, coords: {lat: coords.lat(), lng: coords.lng()}})
    }
    return ({lat: 0, lng: 0})
  })
}

const Map = ({value, onChange}: Props) => {
  const {coords: {lat, lng}} = value
  const onDragEnd = (ev: google.maps.MouseEvent) => {
    const coords = {lat: ev.latLng.lat(), lng: ev.latLng.lng()}
    geocode(coords, (address) => {
      onChange({address, coords})
    })
  }
  const onClick = (ev: google.maps.MouseEvent) => {
    const coords = {lat: ev.latLng.lat(), lng: ev.latLng.lng()}
    geocode(coords, (address) => {
      onChange({address, coords})
    })
  }
  return (
    <GoogleMap center={{lat, lng}} zoom={4} mapContainerStyle={{height: '576px'}}
               onClick={ev => onClick(ev)}>
      <Marker position={{lat, lng}} draggable={true}
              onDragEnd={ev => onDragEnd(ev)}/>
    </GoogleMap>
  )
}

const LocationCoords = ({value, onChange}: Props) =>
  <Map value={value} onChange={onChange}/>


const LocationPicker = ({value, onChange}: Props) => {
  return (<div>
    <LocationAddress value={value} onChange={onChange}/>
    <div className='my-3  WP-map'/>
    <LocationCoords value={value} onChange={onChange}/>
  </div>)
}

export default LocationPicker
