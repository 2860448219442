import { Category, Item, makeItem, Profile, allProfiles, blankProfile } from './Equipment'


export const initialEquipmentModel: EquipmentModel = {
  profile: blankProfile,
}

export interface EquipmentModel {
  profile: Profile
}

export type EquipmentAction
  = { kind: 'select', profileIndex: number }
  | { kind: 'new', categoryId: string, }
  | { kind: 'delete', categoryId: string, itemId: string }
  | { kind: 'clear' }
  // update items fields
  | { kind: 'name', value: string, categoryId: string, itemId: string }
  | { kind: 'nameplatePower', value: number, categoryId: string, itemId: string }
  | { kind: 'averagePower', value: number, categoryId: string, itemId: string }
  | { kind: 'quantity', value: number, categoryId: string, itemId: string }
  | { kind: 'on', categoryId: string, itemId: string }
  | { kind: 'hour', index: number, value: number, categoryId: string, itemId: string }


// TODO: refactor to use immutable.js to simplify deep updates
export const equipmentReducer = (state: EquipmentModel, action: EquipmentAction): EquipmentModel => {
  switch (action.kind) {
    case 'select':
      return {
        ...state,
        profile: allProfiles[action.profileIndex],
      }
    case 'new': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)

      if (categoryIndex != -1) {
        const category = profile.categories[categoryIndex]
        const newCategory = { ...category, items: category.items.concat([makeItem()]) }
        const newProfile = { ...profile, categories: replace(profile.categories, categoryIndex, newCategory) }
        return {
          ...state,
          profile: newProfile,
        }
      }
      else {
        const category = profile.categories.filter(x => x.subCategories?.some(s => s.id === action.categoryId))[0]
        const newCategoryIndex = profile.categories.findIndex(x => x.id === category.id)
        const subcategoryIndex = category.subCategories?.findIndex(s => s.id === action.categoryId)
        if (subcategoryIndex) {
          const subCategory = category.subCategories?.[subcategoryIndex]
          const newSubCategory = { ...subCategory, items: subCategory?.items.concat([makeItem()]) }
          const newCategory = { ...category, subCategories: replace(category.subCategories! as Category[], subcategoryIndex, newSubCategory as Category) }
          const newProfile = { ...profile, categories: replace(profile.categories, newCategoryIndex, newCategory) }
          return {
            ...state,
            profile: newProfile,
          }
        }
        else {
          return {
            ...state,
            profile: profile
          }
        }
      }
    }
    case 'delete': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)

      if (categoryIndex != -1) {
        const category = profile.categories[categoryIndex]
        const newCategory = { ...category, items: category.items.filter(x => x.id !== action.itemId) }
        const newProfile = { ...profile, categories: replace(profile.categories, categoryIndex, newCategory) }
        return {
          ...state,
          profile: newProfile,
        }
      }
      else {
        const category = profile.categories.filter(x => x.subCategories?.some(s => s.id === action.categoryId))[0]
        const newCategoryIndex = profile.categories.findIndex(x => x.id === category.id)
        const subcategoryIndex = category.subCategories?.findIndex(s => s.id === action.categoryId)
        if (subcategoryIndex) {
          const subCategory = category.subCategories?.[subcategoryIndex]
          const newSubCategory = { ...subCategory, items: subCategory?.items.filter(x => x.id !== action.itemId) }
          const newCategory = { ...category, subCategories: replace(category.subCategories! as Category[], subcategoryIndex, newSubCategory as Category) }
          const newProfile = { ...profile, categories: replace(profile.categories, newCategoryIndex, newCategory) }
          return {
            ...state,
            profile: newProfile,
          }
        }
        else {
          return {
            ...state,
            profile: profile
          }
        }
      }
    }
    case 'clear': {
      const profile = state.profile
      const newCategories = profile.categories.map(category => {
        return {
          ...category,
          items: category.items.map(item => { return { ...item, quantity: 0 } }),
          subCategories: category?.subCategories?.map(subcategory => {
            return {
              ...subcategory,
              items: subcategory.items.map(subcategoryItem => { return { ...subcategoryItem, quantity: 0 } }),
            }
          })
        }
      })
      const newProfile = { ...profile, categories: newCategories }
      return {
        ...state,
        profile: newProfile,
      }
    }
    case 'name': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)

      if (categoryIndex != -1) {
        const category = profile.categories[categoryIndex]
        const itemIndex = category.items.findIndex(x => x.id === action.itemId)
        const item = category.items[itemIndex]
        const newItem = { ...item, name: action.value }
        const newCategory = { ...category, items: replace(category.items, itemIndex, newItem) }
        const newProfile = { ...profile, categories: replace(profile.categories, categoryIndex, newCategory) }
        return {
          ...state,
          profile: newProfile,
        }
      }
      else {
        const category = profile.categories.filter(x => x.subCategories?.some(s => s.id === action.categoryId))[0]
        const newCategoryIndex = profile.categories.findIndex(x => x.id === category.id)
        const subcategoryIndex = category.subCategories?.findIndex(s => s.id === action.categoryId)
        if (subcategoryIndex) {
          const subCategory = category.subCategories?.[subcategoryIndex]
          const itemIndex = subCategory!.items.findIndex(x => x.id === action.itemId)
          const item = subCategory!.items[itemIndex]
          const newItem = { ...item, name: action.value }
          const newSubCategory = { ...subCategory, items: replace(subCategory!.items, itemIndex, newItem) }
          const newCategory = { ...category, subCategories: replace(category.subCategories! as Category[], subcategoryIndex, newSubCategory as Category) }
          const newProfile = { ...profile, categories: replace(profile.categories, newCategoryIndex, newCategory) }
          return {
            ...state,
            profile: newProfile,
          }
        }
        else {
          return {
            ...state,
            profile: profile
          }
        }
      }
    }
    case 'nameplatePower': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)
      const nameplatePower = action.value

      if (categoryIndex != -1) {
        const category = profile.categories[categoryIndex]
        const itemIndex = category.items.findIndex(x => x.id === action.itemId)
        const item = category.items[itemIndex]
        // for not custom items update average power using duty cycle
        const averagePower = item.custom ? item.averagePower : action.value * item.d
        const newItem = { ...item, nameplatePower, averagePower }
        const newCategory = { ...category, items: replace(category.items, itemIndex, newItem) }
        const newProfile = { ...profile, categories: replace(profile.categories, categoryIndex, newCategory) }
        return {
          ...state,
          profile: newProfile,
        }
      }
      else {
        const category = profile.categories.filter(x => x.subCategories?.some(s => s.id === action.categoryId))[0]
        const newCategoryIndex = profile.categories.findIndex(x => x.id === category.id)
        const subcategoryIndex = category.subCategories?.findIndex(s => s.id === action.categoryId)
        if (subcategoryIndex != -1) {
          const subCategory = category.subCategories?.[subcategoryIndex!]
          const itemIndex = subCategory!.items.findIndex(x => x.id === action.itemId)
          const item = subCategory!.items[itemIndex]
          const averagePower = item.custom ? item.averagePower : nameplatePower * item.d
          const newItem = { ...item, nameplatePower, averagePower }
          const newSubCategory = { ...subCategory, items: replace(subCategory!.items, itemIndex, newItem) }
          const newCategory = { ...category, subCategories: replace(category.subCategories! as Category[], subcategoryIndex!, newSubCategory as Category) }
          const newProfile = { ...profile, categories: replace(profile.categories, newCategoryIndex, newCategory) }
          return {
            ...state,
            profile: newProfile,
          }
        }
        else {
          return {
            ...state,
            profile: profile
          }
        }
      }
    }
    case 'averagePower': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)
      const averagePower = action.value

      if (categoryIndex != -1) {
        const category = profile.categories[categoryIndex]
        const itemIndex = category.items.findIndex(x => x.id === action.itemId)
        const item = category.items[itemIndex]
        if (!item.custom) { return state }
        const newItem = { ...item, averagePower }
        const newCategory = { ...category, items: replace(category.items, itemIndex, newItem) }
        const newProfile = { ...profile, categories: replace(profile.categories, categoryIndex, newCategory) }
        return {
          ...state,
          profile: newProfile,
        }
      }
      else {
        const category = profile.categories.filter(x => x.subCategories?.some(s => s.id === action.categoryId))[0]
        const newCategoryIndex = profile.categories.findIndex(x => x.id === category.id)
        const subcategoryIndex = category.subCategories?.findIndex(s => s.id === action.categoryId)
        if (subcategoryIndex != -1) {
          const subCategory = category.subCategories?.[subcategoryIndex!]
          const itemIndex = subCategory!.items.findIndex(x => x.id === action.itemId)
          const item = subCategory!.items[itemIndex]
          if (!item.custom) { return state }
          const newItem = { ...item, averagePower }
          const newSubCategory = { ...subCategory, items: replace(subCategory!.items, itemIndex, newItem) }
          const newCategory = { ...category, subCategories: replace(category.subCategories! as Category[], subcategoryIndex!, newSubCategory as Category) }
          const newProfile = { ...profile, categories: replace(profile.categories, newCategoryIndex, newCategory) }
          return {
            ...state,
            profile: newProfile,
          }
        }
        else {
          return {
            ...state,
            profile: profile
          }
        }
      }
    }
    case 'quantity': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)

      if (categoryIndex != -1) {
        const category = profile.categories[categoryIndex]
        const itemIndex = category.items.findIndex(x => x.id === action.itemId)
        const item = category.items[itemIndex]
        const newItem = { ...item, quantity: action.value }
        const newCategory = { ...category, items: replace(category.items, itemIndex, newItem) }
        const newProfile = { ...profile, categories: replace(profile.categories, categoryIndex, newCategory) }
        return {
          ...state,
          profile: newProfile,
        }
      }
      else {
        const category = profile.categories.filter(x => x.subCategories?.some(s => s.id === action.categoryId))[0]
        const newCategoryIndex = profile.categories.findIndex(x => x.id === category.id)
        const subcategoryIndex = category.subCategories?.findIndex(s => s.id === action.categoryId)
        if (subcategoryIndex != -1) {
          const subCategory = category.subCategories?.[subcategoryIndex!]
          const itemIndex = subCategory!.items.findIndex(x => x.id === action.itemId)
          const item = subCategory!.items[itemIndex]
          const newItem = { ...item, quantity: action.value }
          const newSubCategory = { ...subCategory, items: replace(subCategory!.items, itemIndex, newItem) }
          const newCategory = { ...category, subCategories: replace(category.subCategories! as Category[], subcategoryIndex!, newSubCategory as Category) }
          const newProfile = { ...profile, categories: replace(profile.categories, newCategoryIndex, newCategory) }
          return {
            ...state,
            profile: newProfile,
          }
        }
        else {
          return {
            ...state,
            profile: profile
          }
        }
      }
    }
    case 'on': {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)

      if (categoryIndex != -1) {
        const category = profile.categories[categoryIndex]
        const itemIndex = category.items.findIndex(x => x.id === action.itemId)
        const item = category.items[itemIndex]
        const newItem = { ...item, hours: [11, 4, 9] }
        const newCategory = { ...category, items: replace(category.items, itemIndex, newItem) }
        const newProfile = { ...profile, categories: replace(profile.categories, categoryIndex, newCategory) }
        return {
          ...state,
          profile: newProfile,
        }
      }
      else {
        const category = profile.categories.filter(x => x.subCategories?.some(s => s.id === action.categoryId))[0]
        const newCategoryIndex = profile.categories.findIndex(x => x.id === category.id)
        const subcategoryIndex = category.subCategories?.findIndex(s => s.id === action.categoryId)
        if (subcategoryIndex != -1) {
          const subCategory = category.subCategories?.[subcategoryIndex!]
          const itemIndex = subCategory!.items.findIndex(x => x.id === action.itemId)
          const item = subCategory!.items[itemIndex]
          const newItem = { ...item, hours: [11, 4, 9] }
          const newSubCategory = { ...subCategory, items: replace(subCategory!.items, itemIndex, newItem) }
          const newCategory = { ...category, subCategories: replace(category.subCategories! as Category[], subcategoryIndex!, newSubCategory as Category) }
          const newProfile = { ...profile, categories: replace(profile.categories, newCategoryIndex, newCategory) }
          return {
            ...state,
            profile: newProfile,
          }
        }
        else {
          return {
            ...state,
            profile: profile
          }
        }
      }
    }
    case "hour": {
      const profile = state.profile
      const categoryIndex = profile.categories.findIndex(x => x.id === action.categoryId)
      const hourIndex = action.index
      const hourValue = action.value
      if (categoryIndex != -1) {
        const category = profile.categories[categoryIndex]
        const itemIndex = category.items.findIndex(x => x.id === action.itemId)
        const item = category.items[itemIndex]
        const newHours = [...item.hours]
        newHours[hourIndex] = hourValue
        const newItem = { ...item, hours: newHours }
        const newCategory = { ...category, items: replace(category.items, itemIndex, newItem) }
        const newProfile = { ...profile, categories: replace(profile.categories, categoryIndex, newCategory) }
        return {
          ...state,
          profile: newProfile,
        }
      }
      else {
        const category = profile.categories.filter(x => x.subCategories?.some(s => s.id === action.categoryId))[0]
        const newCategoryIndex = profile.categories.findIndex(x => x.id === category.id)
        const subcategoryIndex = category.subCategories?.findIndex(s => s.id === action.categoryId)
        if (subcategoryIndex != -1) {
          const subCategory = category.subCategories?.[subcategoryIndex!]
          const itemIndex = subCategory!.items.findIndex(x => x.id === action.itemId)
          const item = subCategory!.items[itemIndex]
          const newHours = [...item.hours]
          newHours[hourIndex] = hourValue
          const newItem = { ...item, hours: newHours }
          const newSubCategory = { ...subCategory, items: replace(subCategory!.items, itemIndex, newItem) }
          const newCategory = { ...category, subCategories: replace(category.subCategories! as Category[], subcategoryIndex!, newSubCategory as Category) }
          const newProfile = { ...profile, categories: replace(profile.categories, newCategoryIndex, newCategory) }
          return {
            ...state,
            profile: newProfile,
          }
        }
        else {
          return {
            ...state,
            profile: profile
          }
        }
      }
    }
    default:
      return state
  }
}


const replace = <T>(items: T[], index: number, item: T): T[] => {
  const pre = items.slice(0, index)
  const suf = items.slice(index + 1)
  return pre.concat([item], suf)
}


export const getItemEnergy = (x: Item): number => x.averagePower * x.quantity * (x.hours[0] + x.hours[1] + x.hours[2]) / 1000

export const getCategoryEnergy = (x: Category): number => {
  return (x.subCategories?.map(a => getCategoryEnergy(a)).reduce((accn, a) => accn + a, 0) ?? 0) +
  x.items
    .map(x => getItemEnergy(x))
    .reduce((acc, x) => acc + x, 0)
}

export const getCategoriesEnergy = (xs: Category[]): number => xs
.map(x => getCategoryEnergy(x))
.reduce((acc, x) => acc + x, 0) 
