import React, { useContext } from 'react'
import { allProfiles } from './Equipment'
import Table from './Table'
import { EquipmentContext } from './EquipmentContext'
import InfoTip from './InfoTip'


export const EquipmentEditor = () => {
  const { state, dispatch } = useContext(EquipmentContext)

  const onSelect = (profileIndex: number) => { dispatch({ kind: 'select', profileIndex }) }
  const onClear = () => { dispatch({ kind: 'clear' }) }

  return (
    <>
      <div className='WB-el-button WP-mg-top--13'>
        <label id="healthTierLable">Healthcare Facility Tiers  
          <span className='flex-column justify-content-center WB-table-label-pd'>
            <InfoTip id="healthTierLable-tip" text="Such tiers populate electric load inputs with suggested consumption estimates for each piece of equipment. The suggested data can be further edited below" />
          </span> 
          </label>&nbsp;&nbsp;       
        {allProfiles.map((x, index) =>
          <button id='profileButton' key={index} className='btn btn-outline-primary mr-3' onClick={() => onSelect(index)}>
            {x.name}<br />{x.description}
          </button>)}
        {state.profile &&
          <button className='btn btn-outline-secondary mr-3' onClick={onClear}>Clear<br />Quantities</button>}
      </div>
      {state.profile &&
        <Table categories={state.profile.categories} />}
    </>
  )
}
