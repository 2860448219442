import React, { useContext, useEffect, useState } from 'react'
import { EquipmentEditor } from './EquipmentEditor'
import Modal from 'react-bootstrap/Modal'
import InputModel from './Model'
import { calculate, delay, getStatus } from './Service'
import { EquipmentContext } from './EquipmentContext'
import { getEquipmentList, getHouseHoldItem, Profile } from './Equipment'
import { GoAlert } from 'react-icons/all'
import InfoTip from './InfoTip'
import LocationPicker from './LocationPicker'
import { getCategoriesEnergy } from './EquipmentModel'
import './Wizard.css'
import HouseholdTable from './HouseholdTable.js'


export type StepProps<T> = {
  value: T;
  onChange: (value: T, isValid?: boolean) => void;
}


type FieldProps = {
  label: string
  id: string
  placeholder?: string
  unit?: string
  tip?: string
  value: number
  disabled?: boolean
  onChange: (value: number) => void
  secondaryField?: SecondaryFieldProperties
}

type SecondaryFieldProperties = {
  label: string
  id: string
  placeholder?: string
  unit?: string
  tip?: string
  value: number
  disabled?: boolean
  onChange?: (value: number) => void
}


const Field = ({ label, id, placeholder, unit, tip, value, disabled, onChange, secondaryField }: FieldProps) => {
  const [state, setState] = useState(value.toString())
  const [secondaryState, setSecondaryState] = useState(secondaryField?.value.toString())
  const [error, setError] = useState(false)
  const [secondaryError, setSecondaryError] = useState(false)

  useEffect(() => {
    setState(value.toString())
  }, [value])

  useEffect(() => {
    setSecondaryState(secondaryField?.value.toString())
  }, [secondaryField?.value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = e.target.value
    setState(newState)
    setError(false)
  }

  const handleSecondaryFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = e.target.value
    setSecondaryState(newState)
    setSecondaryError(false)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newState = e.target.value
    setState(newState)
    const n = Number(newState)
    if (!isNaN(n)) {
      onChange(n)
    } else {
      setError(true)
    }
  }

  const handleSecondaryFieldBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newState = e.target.value
    setSecondaryState(newState)
    const n = Number(newState)
    if (!isNaN(n)) {
      onChange(n)
    } else {
      setSecondaryError(true)
    }
  }

  const s = `form-control field-small ${error ? 'is-invalid' : ''}`
  const ss = `form-control field-small ${secondaryError ? 'is-invalid' : ''}`


  return (
    <div className='form-group row'>
      <div className='col-3 text-right'>
        <label className='col-form-label'>{label}</label>
      </div>
      <div className='col-3'>
        <div className='input-group'>
          <input className={s}
            name={id}
            placeholder={placeholder}
            disabled={disabled}
            value={state}
            onChange={(e) => handleChange(e)}
            onBlur={(e) => handleBlur(e)} />
          {unit &&
            <div className="input-group-append">
              <div className="input-group-text">{unit}</div>
            </div>}
        </div>
      </div>
      {tip &&
        <div className='d-flex flex-column justify-content-center'>
          <InfoTip id={`${id}-tip`} text={tip} />
        </div>}
      {secondaryField &&
        <div className='col-5'>
          <div className='input-group'>
            <input className={ss}
              name={secondaryField.id}
              placeholder={secondaryField.placeholder}
              disabled={secondaryField.disabled}
              value={secondaryState}
              onChange={(e) => handleSecondaryFieldChange(e)}
              onBlur={(e) => handleSecondaryFieldBlur(e)} />
            {secondaryField.unit &&
              <div className="input-group-append">
                <div className="input-group-text">{secondaryField.unit}</div>
              </div>}
          </div>
        </div>}
      {secondaryField?.tip &&
        <div className='d-flex flex-column justify-content-center'>
          <InfoTip id={`${secondaryField.id}-tip`} text={secondaryField?.tip} />
        </div>}
    </div>
  )
}



interface ErrorNoteProps {
  message: string,
}

const ErrorNote = ({ message }: ErrorNoteProps) => {
  return (
    <p key={message} className='text-danger mb-1 WB-error-msg'>
      <GoAlert className='m-1' />
      <span className='align-middle'>{message}</span>
    </p>
  )
}


// all
const fuelOptions = ['Diesel', 'Gasoline', 'Propane']
const storageOptions = ['Li-Ion Battery', 'Lead Acid Battery']


const defaultModel = {
  location: {
    coords: { lat: 9.0324919, lng: 6.4341224 },
    address: 'Nigeria',
  },

  gridStart: 0,
  gridDuration: 0,
  gridCost: 0.15,
  fuelCost: 1, // Diesel: 1, Gasoline: ?, Propane: ?
  pvCost: 1147,
  storageCost: 432, // Li-Ion Battery: 432, Lead Acid Battery: 270
  converterCost: 608,
  generatorCost: 0,
  generatorStart: 0,
  generatorDuration: 0,
  generatorCapacity: 20,

  fuelName: fuelOptions[0],
  storageName: storageOptions[0],

  wiringLength: 0,
  wiringCost: 0,
  wiringOtherCost: 0
}


type Model = typeof defaultModel

const getInputFromModel = (model: Model, profile: Profile): InputModel => {
  return {
    latitude: model.location.coords.lat,
    longitude: model.location.coords.lng,
    address: model.location.address,

    gridStart: model.gridStart,
    gridDuration: model.gridDuration,
    gridCost: model.gridCost,

    fuelCost: model.fuelCost,
    pvCost: model.pvCost,
    storageCost: model.storageCost,
    converterCost: model.converterCost,
    generatorCost: model.generatorCost,
    generatorStart: model.generatorStart,
    generatorDuration: model.generatorDuration,
    generatorCapacity: model.generatorCapacity,

    fuelName: model.fuelName,
    storageName: model.storageName,

    wiringCost: model.wiringCost,
    wiringLength: model.wiringLength,
    wiringOtherCost: model.wiringOtherCost,

    equipment: getEquipmentList(profile)
  }
}

const validateInput = (model: Model, profile: Profile): string[] => {
  const items = profile.categories.flatMap(x => x.items)
  const errors = []
  const eps = 0.001

  // number of hours
  const h1 = items.map(x => x.hours[0]).some(x => x > 11)
  const h2 = items.map(x => x.hours[1]).some(x => x > 4)
  const h3 = items.map(x => x.hours[2]).some(x => x > 9)
  if (h1 || h2 || h3) { errors.push('Incorrect number of hours for some equipment items.') }

  // total load > 0
  const total = getCategoriesEnergy(profile.categories)
  const totalHouseHoldsPower = getHouseHoldItem().power
  if (total < eps && totalHouseHoldsPower < eps) { errors.push('Total load is 0.') }

  // average power <= nameplate power
  const p = items.some(x => x.averagePower > x.nameplatePower + eps)
  if (p) { errors.push('Average power is greater than nameplate power for some equipment items.') }

  return errors
}


const timeInHours = Array.from({ length: 24 }, (_, i) => i)
const timeDurationInHours = Array.from({ length: 25 }, (_, i) => i)

const Wizard = () => {
  const [model, setModel] = useState(defaultModel)

  const equipmentContext = useContext(EquipmentContext)

  const [progress, setProgress] = useState(0)
  const [id, setId] = useState<string | null>(null)
  const [wait, setWait] = useState(false)
  const [ready, setReady] = useState(false)

  const [errors, setErrors] = useState<string[]>([])

  const onChange = (value: Partial<Model>) => {
    const s = { ...model, ...value }
    setModel(s)
  }

  const onStart = async () => {
    // validate model
    const validation = validateInput(model, equipmentContext.state.profile)
    const isValid = validation.length === 0
    setErrors(validation)
    if (!isValid) { return }

    // reset status
    setReady(false)
    setProgress(0)

    // calculate model
    setWait(true)
    const input = getInputFromModel(model, equipmentContext.state.profile)
    const rv = await calculate(input)
    setId(rv.id)
  }

  useEffect(() => {
    if (id === null) { return }

    let cancel = false

    const run = async () => {
      // check status
      let it = 0
      let p = 0
      while (it < 100) {
        if (cancel) { return }
        await delay(5000)
        const rv = await getStatus(id)
        p = Math.max(p, rv.progress)
        setProgress(p)
        if (rv.status === 'Completed' || p >= 100) { break }
        it++
      }

      // redirect if ready
      setReady(true)
      window.open(`result/${id}`, '_blank')
    }

    run()

    return () => {
      cancel = true
    }
  }, [id])

  // reset errors when profile changed
  useEffect(
    () => { setErrors([]) },
    [equipmentContext.state.profile.id],
  )

  const handleHide = () => {
    if (ready) { setWait(false) }
  }

  const { coords: { lat, lng } } = model.location
  const locationLabel = lat.toFixed(3) + ', ' + lng.toFixed(3)

  return (<>
    <div className='py-3' onSubmit={ev => ev.preventDefault()}>

      <fieldset>
        <div className='row'>
          <div className='col-lg-6'>
            <LocationPicker value={model.location} onChange={x => onChange({ location: x })} />
          </div>
          <div className='col-lg-6 WB-pd-t-10px'>
            <legend id='locationLegend'>1. Location</legend>
            <div className='form-group row WB-mg-t-3rem'>
              <div className='col-2 text-right'>
                <label className='col-form-label'>Address</label>
              </div>
              <div className='col-10'>
                <input type='text' readOnly className='form-control-plaintext' id='location' value={model.location.address} />
              </div>
              <div className='col-2 text-right'>
                <label className='col-form-label'>Location</label>
              </div>
              <div className='col-10'>
                <input type='text' readOnly className='form-control-plaintext' id='location' value={locationLabel} />
              </div>
            </div>
            <div className='WB-mg-t-3rem'>
              <legend>2. Households to be Connected by the Project</legend>
              <HouseholdTable />
              {/* <legend> Household Demographics</legend>
              <small >At this stage, the tool collects values only for recording purposes and will not be reflected in system modeling.</small>
              <div className="form-group row"></div>
              <Field label='# of Tier 1 households' unit='' id='tier1Households' secondaryField={{ label: '', unit: 'kWh/day/HH', id: 'tier1HouseholdsPower', value: 0.012, tip: 'The default value represents the minimum electricity consumption for this household tier. The value can range between 12 and 200 Wh/day', onChange: y => () => { } }}
                value={0}
                onChange={x => () => { }} />
              <Field label='# of Tier 2 households' unit='' id='tier2Households' secondaryField={{ label: '', unit: 'kWh/day/HH', id: 'tier2HouseholdsPower', value: 0.2, tip: 'The default value represents the minimum electricity consumption for this household tier. The value can range between 200 Wh/day and 1 kWh/day', onChange: y => () => { } }}
                value={0}
                onChange={x => () => { }} />
              <Field label='# of Tier 3 households' unit='' id='tier3Households' secondaryField={{ label: '', unit: 'kWh/day/HH', id: 'tier3HouseholdsPower', value: 1, tip: 'The default value represents the minimum electricity consumption for this household tier. The value can range between 1 and 3.4 kWh/day', onChange: y => () => { } }}
                value={0}
                onChange={x => () => { }} />
              <Field label='# of Tier 4 households' unit='' id='tier4Households' secondaryField={{ label: '', unit: 'kWh/day/HH', id: 'tier4HouseholdsPower', value: 3.4, tip: 'The default value represents the minimum electricity consumption for this household tier. The value can range between 3.4 and 8.2 kWh/day', onChange: y => () => { } }}
                value={0}
                onChange={x => () => { }} />
              <Field label='# of Tier 5 households' unit='' id='tier5Households' secondaryField={{ label: '', unit: 'kWh/day/HH', id: 'tier5HouseholdsPower', value: 8.2, tip: 'The default value represents the minimum electricity consumption for this household tier. The value must be over 8.2 kWh/day', onChange: y => () => { } }}
                value={0}
                onChange={x => () => { }} /> */}
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend className='WB-Power-heading' id='powerAssumptionsLegend'>3. Power Assumptions</legend>
        <div className='row wp-power-ass'>
          <div className='col-lg-6'>
            <h5>External Grid</h5>

            <div className='form-group row'>
              <div className='col-4 text-right'>
                <label className='col-form-label'>Grid on at time</label>
              </div>
              <div className='col-4'>
                <select className='form-control'
                  defaultValue={model.gridStart}
                  onChange={ev => onChange({ gridStart: Number(ev.target.value) })}>
                  {timeInHours.map(x => <option key={x} value={x}>{`${x}:00`}</option>)}
                </select>
              </div>
              <div className='d-flex flex-column justify-content-center'>
                <InfoTip id='gridonattimeTooltip' text='If grid is available on a regular schedule for part of the day, please include starting hour for grid availability.' />
              </div>
            </div>

            <div className='form-group row'>
              <div className='col-4 text-right'>
                <label className='col-form-label'>Grid on for how many hours?</label>
              </div>
              <div className='col-4'>
                <select className='form-control'
                  defaultValue={model.gridDuration}
                  onChange={ev => onChange({ gridDuration: Number(ev.target.value) })}>
                  {timeDurationInHours.map(x => <option key={x} value={x}>{x}</option>)}
                </select>
              </div>
              <div className='d-flex flex-column justify-content-center'>
                <InfoTip id='gridHowManyHoursTooltip' text='Please provide the number of operating hours of the grid since starting time. In case the grid is unreliable, or in order to model a system that does not use the existing grid as a power source, please enter 0.' />
              </div>
            </div>

            <Field label='Electric Grid Price' unit='$/kWh' id='gridCost' tip='This parameter refers the average price of the grid with the exclusion of any additional fixed costs.'
              value={model.gridCost}
              onChange={x => onChange({ gridCost: x })} />

            <div className='wp-pd-t-3rem'>
              <h5>Balance of Systems</h5>

              <Field label='Wiring Length' unit='meters' id='wiringLength' tip='This parameter is only relevant to new distribution systems that connect households (section 2) and other electric load inputs (section 4). When using an existing distribution system, please enter 0'
                value={model.wiringLength}
                onChange={x => onChange({ wiringLength: x })} />

              <Field label='Wiring Cost' unit='$/meter' id='wiringCost'
                value={model.wiringCost}
                onChange={x => onChange({ wiringCost: x })} />

              <Field label='Other Cost' unit='$' id='wiringOtherCost'
                value={model.wiringOtherCost}
                onChange={x => onChange({ wiringOtherCost: x })} />
            </div>

          </div>

          <div className='col-lg-6'>
            <h5>Generator</h5>

            {/*<Field label='Generator Capacity' unit='kWs' id='generatorCapacity'*/}
            {/*  value={model.generatorCapacity}*/}
            {/*  onChange={x => onChange({ generatorCapacity: x })} />*/}

            {/*<div className='form-group row'>*/}
            {/*  <div className='col-4 text-right'>*/}
            {/*    <label className='col-form-label'>Generator on at time</label>*/}
            {/*  </div>*/}
            {/*  <div className='col-4'>*/}
            {/*    <select className='form-control'*/}
            {/*      defaultValue={model.generatorStart}*/}
            {/*      onChange={ev => onChange({ generatorStart: Number(ev.target.value) })}>*/}
            {/*      {timeInHours.map(x => <option key={x} value={x}>{`${x}:00`}</option>)}*/}
            {/*    </select>*/}
            {/*  </div>*/}
            {/*  <div className='d-flex flex-column justify-content-center'>*/}
            {/*    <InfoTip id='generatorontimeTooltip' text='If generator is available on a regular schedule for part of the day, please include starting hour for generator availability.' />*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className='form-group row'>*/}
            {/*  <div className='col-4 text-right'>*/}
            {/*    <label className='col-form-label'>Generator on for how many hours?</label>*/}
            {/*  </div>*/}
            {/*  <div className='col-4'>*/}
            {/*    <select className='form-control'*/}
            {/*      defaultValue={model.generatorDuration}*/}
            {/*      onChange={ev => onChange({ generatorDuration: Number(ev.target.value) })}>*/}
            {/*      {timeDurationInHours.map(x => <option key={x} value={x}>{x}</option>)}*/}
            {/*    </select>*/}
            {/*  </div>*/}
            {/*  <div className='d-flex flex-column justify-content-center'>*/}
            {/*    <InfoTip id='generatorHowManyHoursTooltip' text='Please provide the number of operating hours of the generator since starting time.' />*/}
            {/*  </div>*/}
            {/*</div>*/}

            <Field label='Generator Installed Cost' unit='$/kW' id='storageCost' tip='Please fill this box only if a new generator is needed. In the case of using an existing generator, please leave this to 0.'
              value={model.generatorCost}
              onChange={x => onChange({ generatorCost: x })} />

            <Field label='Delivered diesel fuel price' unit='$/liter' id='fuelCost'
              value={model.fuelCost}
              onChange={x => onChange({ fuelCost: x })} />

            {/* <div className="form-group row">----------------------------------------------------------------------------------------------------------------------------------------</div> */}
            <h5 className='wp-pd-t-3rem'>PV System</h5>
            <Field label='Cost of PV System' unit='$/kW' id='pvCost' tip='Please include the cost of all related components (such as panels, installation, and others) with the exclusion of inverter and batteries.'
              value={model.pvCost}
              onChange={x => onChange({ pvCost: x })} />

            <div className='form-group row'>
              <div className='col-4 text-right'>
                <label className='col-form-label'>Type of Battery</label>
              </div>
              <div className='col-4'>
                <select className='form-control'
                  defaultValue={model.storageName}
                  onChange={ev => onChange({ storageName: ev.target.value })}>
                  {storageOptions.map(x => <option key={x} value={x}>{x}</option>)}
                </select>
              </div>
            </div>

            <Field label='Cost of Batteries' unit='$/kWh' id='storageCost'
              value={model.storageCost}
              onChange={x => onChange({ storageCost: x })} />

            <Field label='Cost of bi-directional Inverter' unit='$/kW' id='storageCost' tip='This parameter should also include the cost of the monitoring system.'
              value={model.converterCost}
              onChange={x => onChange({ converterCost: x })} />
          </div>
        </div>

      </fieldset>

      {/* <fieldset>
        <legend>3) Household Demographics</legend>
        <small >* Default value estimates based on the Multi-tier Framework <a href='https://mtfenergyaccess.esmap.org/methodology/electricity'>mtfenergyaccess.esmap.org/methodology/electricity</a></small>
        <div className ="form-group row"></div>
        <Field label='# of Tier 1 households' unit='' id='tier1Households' secondaryField={{ label: '', unit: 'kWh/day/HH', id: 'tier1HouseholdsPower', value: 0.02, tip: 'At least 12 Wh /day*', onChange: y => () => { } }}
          value={0}
          onChange={x => () => { }} />
        <Field label='# of Tier 2 households' unit='' id='tier2Households' secondaryField={{ label: '', unit: 'kWh/day/HH', id: 'tier2HouseholdsPower', value: 0.3, tip: 'At least 200 Wh /day*', onChange: y => () => { } }}
          value={0}
          onChange={x => () => { }} />
        <Field label='# of Tier 3 households' unit='' id='tier3Households' secondaryField={{ label: '', unit: 'kWh/day/HH', id: 'tier3HouseholdsPower', value: 1.5, tip: 'At least 1 kWh /day*', onChange: y => () => { } }}
          value={0}
          onChange={x => () => { }} />
        <Field label='# of Tier 4 households' unit='' id='tier4Households' secondaryField={{ label: '', unit: 'kWh/day/HH', id: 'tier4HouseholdsPower', value: 5, tip: 'At least 3.4 kWh /day*', onChange: y => () => { } }}
          value={0}
          onChange={x => () => { }} />
        <Field label='# of Tier 5 households' unit='' id='tier5Households' secondaryField={{ label: '', unit: 'kWh/day/HH', id: 'tier5HouseholdsPower', value: 10, tip: 'At least 8.2 kWh /day*', onChange: y => () => { } }}
          value={0}
          onChange={x => () => { }} />


      </fieldset> */}

      <fieldset>
        <legend id='electricLoadLegend' className='WP-electricLoadLegend-heading'>4. Electric Load Inputs</legend>

        <EquipmentEditor />
      </fieldset>

      <fieldset>
        <legend id='runHomerLegend' className='WB-homer-run text-center'>5. Run HOMER</legend>

        {errors.map(x => <ErrorNote message={x} />)}

        <button id='calculate' className='btn btn-success wp-calculate-btn' onClick={onStart}>Calculate</button>
      </fieldset>

    </div>

    <Modal show={wait} centered onHide={handleHide}>
      <Modal.Header closeButton={ready}>
        <Modal.Title>{!ready ? 'Calculating...' : 'Completed'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!ready ?
          <div className='progress'>
            <div className='progress-bar progress-bar-striped progress-bar-animated bg-success'
              role='progressbar' aria-valuemin={0} aria-valuemax={100} aria-valuenow={progress}
              style={{ width: `${progress}%` }} />
          </div> :
          <>
            <div>
              <a id='viewResults' href={`result/${id}`} target='_blank'>View Results</a>
            </div>
          </>}
      </Modal.Body>
    </Modal>
  </>
  )
}

export default Wizard
