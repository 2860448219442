import React from 'react'

const Outro = () => {
  return (
    <>
      <div className='py-3'>
        <h5>About the tool</h5>
        <p>
          The HOMER Powering Displacement Settings Tool uses the proprietary optimization algorithm of HOMER (Hybrid Optimization Model for Multiple Energy Resources), originally developed at the United States National Renewable Energy Laboratory (NREL), and enhanced and distributed by <a href='https://www.homerenergy.com/'>HOMER Energy by UL</a>, a subsidiary of UL. 
          This model builds on the HOMER Powering Healthcare tool and has been made available with funding and technical support from the Leave No One Behind unit of the World Bank’s Energy Sector Management Assistance Program (ESMAP), as well as with contributions from numerous experts. Any feedback is welcome and can be addressed to <a href='mailto:support@homerenergy.com'>support@homerenergy.com</a>.
        </p>

        <p>
          <strong>Disclaimer.</strong> The services of the website are provided strictly on an “as is” basis. There are no warranties, express or implied, including, but not limited to, any implied warranties of merchantability or fitness for a particular purpose, originality, results to be derived from the services or non-infringement of third party rights with respect to this agreement or the services. HOMER Energy by UL makes no representation, warranty, or guaranty as to the reliability, quality, suitability, availability, accuracy or completeness of the services provided by this tool. By using this tool, users expressly acknowledge that the services are still in the development process, have not been fully tested, and may not operate error-free. By agreeing to participate in the testing of the services, the user accepts all risks associated with the services and the operation thereof without limitation. Service providers should ensure that systems follow codes of practice for electrical safety and comply with all applicable national and local laws. Specialized codes for electrical installations in medical locations (e.g. IEC 60364-7-710) may also be relevant. This tool is not designed to meet a specific code.
        </p>
      </div>
      <div className='text-center'>
        <a href='https://www.homerenergy.com/'>
          <img src='homerpro.png' alt='HOMER' className='imgsize' />
        </a>
      </div>
    </>
  )
}

export default Outro
