import React, {useEffect, useState} from 'react'


type FieldProps = {
  id: string
  value: number
  onChange: (value: number) => void
  max?: number
}


const NumberInput = ({id, value, onChange, max}: FieldProps) => {
  const [state, setState] = useState(value.toString())
  const [error, setError] = useState(false)

  useEffect(() => {
    setState(value.toString())
    if (max) {
      setError(value > max)
    }
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newState = e.target.value
    setState(newState)
    setError(false)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newState = e.target.value
    setState(newState)
    const n = Number(newState)
    if (!isNaN(n)) {
      if (max) {
        setError(value > max)
      }
      onChange(n)
    } else {
      setError(true)
    }
  }

  const s = `form-control form-control-sm ${error ? 'is-invalid' : ''}`

  return (
    <input className={s}
           name={id}
           value={state}
           onChange={(e) => handleChange(e)}
           onBlur={(e) => handleBlur(e)}/>
  )
}

export default NumberInput
