import React, {ReactNode, useState} from 'react'
import {GoChevronDown, GoChevronRight} from 'react-icons/go'


type InfoTipProps = { children: ReactNode }

const InfoTip = ({children}: InfoTipProps) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <a className="moreInfoLink" href="/#" onClick={_ => setShow(!show)}>[more info]</a>
      {show &&
      <div className="moreInfoText">{children}</div>}
    </>)
}


type StepTipProps = {
  title: string
  description: string
  children: ReactNode
}

const StepTip = ({children, description, title}: StepTipProps) => {
  return (
    <li>
      <b>{title}</b> - {description}
      <InfoTip>
        {children}
      </InfoTip>
    </li>
  )
}


type CollapsibleSectionProps = {
  title: string,
  children: ReactNode,
  expanded: boolean,
}

const CollapsibleSection = ({title, children, expanded}: CollapsibleSectionProps) => {
  const [show, setShow] = useState(expanded)
  const toggle = () => { setShow(!show) }
  const iconStyle = {marginBottom: '3px'}

  return (
    <>
      <h5 onClick={toggle} style={{cursor: 'pointer'}}>
        <span>
          {show ?
            <GoChevronDown style={iconStyle}/> :
            <GoChevronRight style={iconStyle}/>}
        </span>
        <span>{title}</span>
      </h5>
      {show && children}
    </>
  )
}


const Intro = () => {
  return (
    <div className='py-3 WP-intro'>
      <h2>HOMER<sup>®</sup> Enabled to Electrify Displacement Settings</h2>

      <CollapsibleSection title='What is this tool for?' expanded={true}>
        <p>
          The HOMER Enabled Tool is a free webapp that creates the initial design and outlines the cost of electrifying displacement settings via solar-powered mini-grid systems.
        </p>
        <p>
          Diesel generators are common in contexts of displacement as they can be deployed quickly; this, however, presents on-going challenges including fuel delivery and logistics, cost, maintenance, noise, and pollution. To address these issues, the tool provides the initial parameters needed to retrofit existing diesel-powered systems with solar PV modules and batteries. Based on the inputs provided by the users, the model simulates different mixes of power supply options to meet electrical loads and identifies the least-cost design comparing combinations of grid electricity (if available), batteries (lithium-ion or lead-acid), solar PV, and diesel generator sets.
        </p>
        <p>
          Practitioners in humanitarian settings – even those with limited experience with solar PV technology – can use the HOMER Enabled Tool to increase the sustainability of electric power systems and address the challenges posed by diesel fuel consumption. The tool also creates a complete HOMER file that can be downloaded and run in the HOMER desktop software for further analysis by design professionals.
        </p>
      </CollapsibleSection>

      <CollapsibleSection title='How to use the tool?' expanded={false}>
        <ol>
          <StepTip
            title="Location"
            description="This section outlines the key geographical and household data related to the project.">
            <p>
              The location is key to determine the amount of energy available from the sun. The HOMER tool obtains monthly average solar resource values from a variety of databases at NREL and NASA to synthesize a year of hourly data. These are calculated based on the latitude and longitude of a site and satellite measurements of annual cloud cover.
            </p>
          </StepTip>
          <StepTip
            title="Households to be connected by the project"
            description="This section captures the households connected by the project.">
            <p>
              The Household Demographics section makes use of the World Bank’s Multi-Tier Framework (MTF, <a href='https://mtfenergyaccess.esmap.org/methodology/electricity'>mtfenergyaccess.esmap.org/methodology/electricity</a>) and reflects the minimum estimated consumption per each household tier.
            </p>
            <p>
              Users can provide the number of households to be connected per each MTF consumption tier. The default household electricity consumption provided by default corresponds to the minimum consumption of each MTF tier.
            </p>
          </StepTip>
          <StepTip
            title="Power Assumptions"
            description="This section reflects the key power inputs and features of the existing Power System to be retrofit via the HOMER Tool, as well as the inputs necessary to introduce a PV System. The values provided in the section are default. As such, users are encouraged to enter local values for a more effective estimate.">
            <p>
              <strong>External Grid :</strong>
              <p>This subsection addresses the details of the existing power grid in target areas.</p>
              <p>In areas where the electricity grid is available only on a regular schedule for part of the day, the scheduling of the grid can have a significant impact on the design of a suitable power supply system. If the grid is completely unreliable, or the user wants to model a power system that does not include the external grid as a power source, the parameter “Grid on for how many hours” should be set to 0.</p>
              <p>Grid electricity prices may be estimated from a recent bill or from online sources.</p>
              {/* In areas where the electricity grid is available only on a regular schedule for part of the day, the scheduling of the grid can have a significant impact on the design of a suitable power supply system. In areas where the grid is very unreliable, it may be best to design a system as if there is no grid at all or use the desktop version of HOMER Pro for more detailed analysis. The availability and cost of supply and installation of PV panels, batteries and diesel fuel will vary by location. The default cost values entered here are roughly accurate for much of Africa but should not be relied upon without verification. Grid electricity costs may be estimated from a recent bill. When calculating the cost of PV, include the cost of all related components (the cost of the panels, installation, mounting frames and other hardware), but not the inverter or batteries, which are sized separately by HOMER. Retail pump prices by country for petrol/gasoline and diesel can be found at https://data.worldbank.org/indicator or https://www.globalpetrolprices.com. We have set the default cost of the diesel generator to zero on the assumption that the user is retrofitting an existing diesel. If a new diesel generator is needed this cost should be input by the user.             */}
              
              <strong>Balance of Systems :</strong>
              <p>This subsection addresses any new distribution system that relates to the proposed project. The figures provided in this subsection state the cost and the length of new wiring lines needed to connect households, healthcare facilities and any other electrical load powered by the system.</p>
              <p>
                Wiring cost and length indicators should be filled only if the project includes novel distribution lines. If the system uses an already existing distribution system, values should be set to 0.
              </p>

              
              <strong>Generator :</strong>
              <p>This subsection outlines the details of an existing diesel generator, as well as the cost connected with the installation of a new one.</p>
              <p>The system assumes an existing generator; as such, the “Generator Installed Cost” box is set to 0. Users should update it in case a new generator is installed. </p>
              <p>Retail pump prices by country for petrol/gasoline and diesel can be found at <a href='https://data.worldbank.org/indicator'> https://data.worldbank.org/indicator</a> or <a href='https://www.globalpetrolprices.com'>https://www.globalpetrolprices.com</a>. The cost of delivering this fuel to the site should be added to the retail pump price when appropriate.</p>
              
              <strong>PV System :</strong>
              <p>This subsection addresses the details of the PV system that is going be integrated in the project. </p>
              <p>The availability and cost of supply and installation of PV panels, batteries and diesel fuel will vary by location. The default cost values provided are accurate for most African countries but should be further verified by the users.</p>
              <p>The “Cost of PV System” parameter should include the cost of all related components (the cost of the panels, installation, mounting frames and other hardware), but not the inverter or batteries, which are sized separately by HOMER in the “Cost of Batteries” and “Cost of bi-directional inverter” sections.</p>

              </p>
          </StepTip>

          {/* <StepTip
            title="Financial Assumptions"
            description="Enter the Real Interest rate.">
            <p>
              HOMER minimizes the discounted cash flow of the project. The interest rate determines the tradeoff between the initial capital cost and future operating costs. It has a significant effect on the optimal system design. Lower interest rates will favor PV over diesel generators. The real interest rate can be approximated as the difference between a bank's interest rate on loans and the estimated rate of inflation.
            </p>
          </StepTip> */}

          <StepTip
            title="Electric Load Inputs"
            description="This section estimates the type and number of electrical devices powered by the existing system and the time of day they are used. The quantity of devices and hours of use can be adjusted or entered manually. ">
            <p>
              In the case of health facilities, the tool provides four standardized consumption tiers as a starting point. Users can select such tiers and the tool will estimate the type and number of relevant power equipment. At this stage, the Tool only allows the selection of one Health Facility tier at a time. In case the project involves multiple health facilities, please populate each input line in the table as required by the electrification needs of all of the facilities.
            </p>
            <p>
              Users are encouraged to further refine the estimates provided by overwriting the values in the cells. The user can also add as many additional rows to each section as necessary to consider whatever additional equipment might be at the site.
            </p>
            <p>
              For a more accurate simulation, this step may require an on-site audit.  In all cases, the equipment quantity, power, and hours of use are inputs that should be reviewed and adjusted as needed to meet the needs of a given facility.
            </p>
            
          </StepTip>

          <StepTip
            title="Run HOMER"
            description="Once all the necessary inputs have been defined, click on the “Run HOMER” button. The tool will then analyze every possible combination of power supply options that meet the demand. The results will appear in a new page if your browser allows pop-ups; otherwise, you will be provided a link to access the results in a new page.">
            <p>
              The results page will provide a table of optimized configurations ranked by the cost per unit of electricity over the project lifetime. Results will include configurations for PV-battery, PV-battery-backup generator, battery-generator, and generator only (for comparison).
            </p>
            <p>
              Users can then select a given configuration to access more detailed results. In addition, the tool creates a HOMER Pro file that can also be downloaded for users to perform a more detailed optimization with the HOMER Pro desktop application.
            </p>
          </StepTip>
        </ol>

        <p>
          <strong>Note: The tool is not intended to create final designs. </strong>
        </p>
        
      </CollapsibleSection>
    </div>
  )
}

export default Intro
